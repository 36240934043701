import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    commonDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: null
    }
  }
};