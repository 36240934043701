//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import PreventUnload from "vue-prevent-unload";
import Dialog from "@/view/pages/partials/Dialog";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
export default {
  props: {
    customClass: {
      type: String,
      default: null
    },
    headerLeftClass: {
      type: String,
      default: null
    },
    headerRightClass: {
      type: String,
      default: null
    },
    routePreventDialog: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    stayOnPage: function stayOnPage() {
      PreventDialogEventBus.$emit("stay-on-page", true);
    },
    leavePage: function leavePage() {
      PreventDialogEventBus.$emit("leave-page", true);
    }
  },
  components: {
    // PreventUnload,
    Dialog: Dialog
  }
};