import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import Vue from "vue";
import { toSafeInteger } from "lodash";

var isEmpty = function isEmpty(param) {
  return Vue.lodash.isEmpty(param);
};

var isObject = function isObject(param) {
  return Vue.lodash.isObject(param);
};

export var validateEmail = function validateEmail(email) {
  //eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export default {
  data: function data() {
    return {
      formLoading: false,
      formValid: true,
      formReadOnly: true,
      formErrors: [],
      validateRules: {
        required: function required(val, field, isInteger) {
          if (isObject(val) && isEmpty(val)) {
            return "".concat(field, " is required");
          }

          if (isInteger && parseInt(val) <= 0) {
            return "".concat(field, " is required.");
          }

          return !!val || "".concat(field, " is required");
        },
        validateQty: function validateQty(val, maxQty, field) {
          var newQty = toSafeInteger(val);
          var newAvailable = toSafeInteger(maxQty);

          if (newQty > newAvailable) {
            return "".concat(field, " is must be less than or equal to ").concat(newAvailable, ".");
          }

          return true;
        },
        validEmail: function validEmail(val, field) {
          if (val) {
            if (validateEmail(val)) {
              return true;
            }

            return "".concat(field, " must be valid");
          }

          return true;
        },
        validFloat: function validFloat(val, field) {
          if (val) {
            if (/^\d*\.?\d*$/.test(val)) {
              return true;
            }

            return "".concat(field, " must be valid");
          }

          return true;
        },
        validNumeric: function validNumeric(val, field) {
          if (val) {
            if (/^\d+$/.test(val)) {
              return true;
            }

            return "".concat(field, " must be valid (only digits) & greater than 0");
          }

          return true;
        },
        maxLength: function maxLength(val, field, maxLen) {
          if (val) {
            val = val.toString();

            if (val.length <= maxLen) {
              return true;
            }

            return "".concat(field, " length must be less than or equal to ").concat(maxLen);
          }

          return true;
        },
        minLength: function minLength(val, field, minLen) {
          if (val) {
            val = val.toString();

            if (val.length >= minLen) {
              return true;
            }

            return "".concat(field, " length must be greater than or equal to ").concat(minLen);
          }

          return true;
        },
        lessThan: function lessThan(val, field, len) {
          if (val) {
            val = parseInt(val);

            if (val <= len) {
              return true;
            }

            return "".concat(field, " must be less than or equal to ").concat(len);
          }

          return true;
        },
        greaterThan: function greaterThan(val, field, minLen) {
          if (val) {
            val = val.toString();

            if (val.length >= minLen) {
              return true;
            }

            return "".concat(field, " length must be greater than or equal to ").concat(minLen);
          }

          return true;
        },
        deleteValidation: function deleteValidation(val, field, barcode) {
          if (val) {
            val = val.toString();
            barcode = barcode.toString();

            if (val === barcode) {
              return true;
            }

            return "".concat(field, " must be equal to ").concat(barcode);
          }

          return true;
        },
        confirmPassword: function confirmPassword(val, field, old) {
          if (val) {
            val = val.toString();
            old = old.toString();

            if (val === old) {
              return true;
            }

            return "".concat(field, " you entered don't match to new password");
          }

          return true;
        }
      }
    };
  }
};