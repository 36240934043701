//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    message: {
      type: String,
      default: "Are you sure, you want to delete this ?"
    }
  }
};