//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "notification-and-remarks",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    customer: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      notification: {
        client_reminders: 1,
        client_follow_up_emails: 1,
        print_email_on_work_order: 1,
        customer_remark: null
      },
      fieldDescriptions: [
        /*{
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }*/
      ]
    };
  },
  watch: {
    notification: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.$emit("saveNotification", param);
      }
    },
    options: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.notification.customer_remark = param.remarks;
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.lodash.isEmpty(this.customer) === false) {
      this.notification.client_reminders = this.customer.client_reminders;
      this.notification.client_follow_up_emails = this.customer.client_follow_up_emails;
      this.notification.print_email_on_work_order = this.customer.print_email_on_work_order;
      this.notification.customer_remark = this.customer.customer_remark;
    }
  }
};