import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { DOWNLOAD, DELETE, UPLOAD } from "@/core/services/store/request.module";
import fileDownload from "js-file-download";
var BASE_PATH = process.env.VUE_APP_BASE_URL;
var AllowedExtension = ["csv", "pdf", "xls", "ppt", "pptx", "zip", "gif", "jpeg", "jpg", "png", "txt", "doc", "docx", "xlsx", "svg"];
var AllowedImageExtension = ["gif", "jpeg", "jpg", "png"];
var FileUploadCountLimit = 5;
var FileUploadSizeLimit = 10 * 1024 * 1024; // 10 MB

export default {
  data: function data() {
    return {
      dataDownloading: {},
      dataDeleting: {},
      primaryLoader: false,
      uploadCountLimit: FileUploadCountLimit,
      uploadSizeLimit: FileUploadSizeLimit,
      documents: [],
      dropzoneOptions: {
        maxFiles: FileUploadCountLimit,
        dropzoneOptions: false,
        url: this.$apiURL + "file-manager/documents",
        parallelUploads: FileUploadCountLimit,
        uploadMultiple: true,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles();
          this.addFile(file);
        }
      }
    };
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  methods: {
    localFileExists: function localFileExists(path) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          var filePath = BASE_PATH + path;
          fetch(filePath, {
            method: "head"
          }).then(function (status) {
            resolve(status.ok);
          }).catch(function (status) {
            reject(status.ok);
          });
        } catch (error) {
          _this.logError(error);

          reject(false);
        }
      });
    },
    getFileExtension: function getFileExtension(path) {
      return this.lodash.last(path.split("."));
    },
    getFileName: function getFileName(path) {
      return this.lodash.last(path.split("/"));
    },
    isAllowedExtension: function isAllowedExtension(path) {
      var fileExtension = this.lodash.last(path.split("."));
      return this.lodash.includes(AllowedExtension, fileExtension);
    },
    isAllowedImageExtension: function isAllowedImageExtension(path) {
      var fileExtension = this.lodash.last(path.split("."));
      return this.lodash.includes(AllowedImageExtension, fileExtension);
    },
    isDownloading: function isDownloading(index) {
      if (this.dataDownloading[index]) {
        return true;
      }

      return false;
    },
    isDeleting: function isDeleting(index) {
      if (this.dataDeleting[index]) {
        return true;
      }

      return false;
    },
    deleteAPIFile: function deleteAPIFile(id, index, documents) {
      var _this = this;

      try {
        var deletePromise = function deletePromise() {
          return new Promise(function (resolve, reject) {
            try {
              _this.primaryLoader = true;
              _this.dataDeleting[index] = true;

              _this.$store.dispatch(DELETE, {
                url: "file-manager/delete/" + id
              }).then(function (response) {
                resolve(response);
              }).catch(function (error) {
                reject(error);
              }).finally(function () {
                _this.primaryLoader = false;
                _this.dataDeleting = new Object();
              });
            } catch (error) {
              reject(error);
            }
          });
        };

        deletePromise().then(function () {
          documents.splice(index, 1);
        }).catch(function (error) {
          _this.logError(error);
        });
      } catch (error) {
        _this.logError(error);
      }
    },
    downloadAPIFile: function downloadAPIFile(id, fileName, index) {
      var _this = this;

      try {
        var downloadPromise = function downloadPromise() {
          return new Promise(function (resolve, reject) {
            try {
              _this.primaryLoader = true;
              _this.dataDownloading[index] = true;

              _this.$store.dispatch(DOWNLOAD, {
                url: "file-manager/download/" + id
              }).then(function (response) {
                resolve(response);
              }).catch(function (error) {
                reject(error);
              }).finally(function () {
                _this.primaryLoader = false;
                _this.dataDownloading = new Object();
              });
            } catch (error) {
              reject(error);
            }
          });
        };

        downloadPromise().then(function (response) {
          fileDownload(response.data, fileName);
        }).catch(function (error) {
          _this.logError(error);
        });
      } catch (error) {
        _this.logError(error);
      }
    },
    uploadAttachment: function uploadAttachment(file) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.primaryLoader = true;
          var formData = new FormData();
          formData.append("files[0]", file, file.name);

          _this.$store.dispatch(UPLOAD, {
            url: "file-manager/upload",
            data: formData
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          }).finally(function () {
            _this.primaryLoader = false;
          });
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};