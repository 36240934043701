import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ValidationMixin from "@/core/plugins/validation-mixin";
import allCountries from "@/core/plugins/country-list";
export default {
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: "phone"
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function value(param1, param2) {
      if (param1 != param2) {
        this.setValues();
      }
    }
  },
  data: function data() {
    return {
      phone: null,
      countryCode: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0
      },
      activeCountry: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0
      },
      phoneMask: "+65-########",
      countryList: allCountries
    };
  },
  methods: {
    setValues: function setValues() {
      var value = this.lodash.replace(this.value, "+", "");
      var phoneCode = this.lodash.split(value, "-", 1);

      if (phoneCode && phoneCode[0]) {
        var countryCode = this.lodash.find(this.countryList, {
          dialCode: phoneCode[0]
        });

        if (countryCode) {
          this.countryCode = countryCode;
          this.activeCountry = countryCode;
        }
      }

      this.phone = this.value ? this.value : null;
    },
    updateActiveCountry: function updateActiveCountry() {
      var dialCode = this.countryCode.dialCode;

      if (dialCode) {
        if (dialCode == "65") {
          this.phoneMask = "+".concat(dialCode, "-########");
        } else {
          this.phoneMask = "+".concat(dialCode, "-##########");
        }
      }

      this.phone = null;
      this.activeCountry = this.countryCode;
    }
  },
  computed: {
    rules: function rules() {
      var dialCode = this.countryCode.dialCode;
      var maxLimit = dialCode == "65" ? 12 : 16;
      var minLimit = dialCode == "65" ? 12 : 16;
      var rule = [this.validateRules.minLength(this.phone, "phone number", minLimit), this.validateRules.maxLength(this.phone, "phone number", maxLimit)];

      if (this.required) {
        rule.push(this.validateRules.required(this.phone, "phone number"));
      }

      return rule;
    }
  },
  mounted: function mounted() {
    this.setValues();
  }
};