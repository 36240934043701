import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { GET, QUERY } from "@/core/services/store/request.module";
export default {
  data: function data() {
    return {
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_property_dialog: false,
      transaction_billing: 0,
      transaction_customer: 0,
      transaction_contact_person: 0,
      transaction_property: 0
    };
  },
  methods: {
    createCustomer: function createCustomer() {},
    createContactPerson: function createContactPerson() {},
    createCustomerProperty: function createCustomerProperty() {},
    getJobCustomers: function getJobCustomers(search) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "customer-project-contract-list",
            data: {
              search: search
            }
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomers: function getCustomers(search, entity) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "customer-list",
            data: {
              search: search,
              entity: entity
            }
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerPersons: function getCustomerPersons(customer, search) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store.dispatch(QUERY, {
              url: "contact-person-list/" + customer,
              data: {
                search: search
              }
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerProperties: function getCustomerProperties(customer, search) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store.dispatch(QUERY, {
              url: "property-list/" + customer,
              data: {
                search: search
              }
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerBilling: function getCustomerBilling(customer, search) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store.dispatch(QUERY, {
              url: "billing-list/" + customer,
              data: {
                search: search
              }
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomer: function getCustomer() {
      var _this2 = this;

      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "customer/" + _this.customer
          }).then(function (response) {
            var billingAddress = _this2.getBillingAddress(response.data);

            _this2.transaction_billing = billingAddress.id;
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          }).finally(function () {
            _this.masterDialogClose();
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson: function getDefaultPerson(customer) {
      var defaultPerson = {};

      var _this = this;

      if (customer && _this.lodash.isEmpty(customer.persons) === false) {
        for (var i = 0; i < customer.persons.length; i++) {
          if (customer.persons[i].default) {
            defaultPerson = _this.lodash.cloneDeep(customer.persons[i]);
          }
        }
      }

      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPersonById: function getPersonById(customer, id) {
      var defaultPerson = {};

      var _this = this;

      if (customer && _this.lodash.isEmpty(customer.persons) === false) {
        for (var i = customer.persons.length - 1; i >= 0; i--) {
          if (customer.persons[i].id == id) {
            defaultPerson = _this.lodash.cloneDeep(customer.persons[i]);
          }
        }
      }

      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPropertyById: function getPropertyById(customer, id) {
      var property = {};

      var _this = this;

      if (customer && _this.lodash.isEmpty(customer.address) === false) {
        for (var i = customer.address.length - 1; i >= 0; i--) {
          if (customer.address[i].id == id) {
            property = _this.lodash.cloneDeep(customer.address[i]);
          }
        }
      }

      return _this.lodash.cloneDeep(property);
    },
    getBillingAddress: function getBillingAddress(customer) {
      var billing = {};

      var _this = this;

      if (customer && _this.lodash.isEmpty(customer.address) === false) {
        for (var i = customer.address.length - 1; i >= 0; i--) {
          if (customer.address[i].type == 2) {
            billing = _this.lodash.cloneDeep(customer.address[i]);
            break;
          }
        }
      }

      return _this.lodash.cloneDeep(billing);
    },
    getEmailDetails: function getEmailDetails(customer) {
      var emailArr = [];

      var _this = this;

      if (customer && _this.lodash.isEmpty(customer.details) === false) {
        for (var i = 0; i < customer.details.length; i++) {
          if (customer.details[i].type === 1) {
            emailArr.push(customer.details[i]);
          }
        }
      }

      return _this.lodash.cloneDeep(emailArr);
    },
    getPhoneDetails: function getPhoneDetails(customer) {
      var phoneArr = [];

      var _this = this;

      if (customer && _this.lodash.isEmpty(customer.details) === false) {
        for (var i = 0; i < customer.details.length; i++) {
          if (customer.details[i].type === 2) {
            phoneArr.push(customer.details[i]);
          }
        }
      }

      return _this.lodash.cloneDeep(phoneArr);
    },
    resetAll: function resetAll() {
      this.transaction_customer = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog: function closeDialog() {
      this.transaction_contact_person_dialog = false;
      this.transaction_property_dialog = false;
    },
    selectCustomerPerson: function selectCustomerPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;

      if (this.transaction_property <= 0) {
        this.transaction_property_dialog = true;
      }

      this.checkJob();
    },
    selectCustomerProperty: function selectCustomerProperty(property) {
      this.transaction_property = this.lodash.toSafeInteger(property);
      this.checkJob();
    },
    checkJob: function checkJob() {
      var _this = this;

      var customer = _this.lodash.toSafeInteger(_this.transaction_customer);

      var contact_person = _this.lodash.toSafeInteger(_this.transaction_contact_person);

      var property = _this.lodash.toSafeInteger(_this.transaction_property);

      var billing = _this.lodash.toSafeInteger(_this.transaction_billing);

      if (_this.transaction_type === "quotation") {
        if (customer > 0 && contact_person > 0 && billing > 0) {
          _this.$router.push(_this.getDefaultRoute("quotation.create", {
            query: {
              customer: customer,
              contact_person: contact_person,
              billing: billing
            }
          }));
        }
      } else if (_this.transaction_type === "visit") {
        if (customer > 0 && contact_person > 0 && property > 0) {
          _this.$router.push(_this.getDefaultRoute("job.create", {
            query: {
              customer: customer,
              contact_person: contact_person,
              property: property
            }
          }));
        }
      } else {
        if (customer > 0 && contact_person > 0 && property > 0) {
          _this.$router.push(_this.getDefaultRoute(_this.transaction_type + ".create", {
            query: {
              customer: customer,
              contact_person: contact_person,
              property: property
            }
          }));
        }
      }
    },
    createTransaction: function createTransaction(customer, param) {
      var _this = this;

      _this.transaction_customer = _this.lodash.toSafeInteger(customer.id);
      _this.transaction_type = _this.lodash.toString(param);

      if (_this.transaction_type === "property") {
        _this.$router.push(_this.getDefaultRoute("property.create", {
          query: {
            customer: _this.transaction_customer
          }
        }));
      } else {
        if (customer.persons_count > 1) {
          _this.transaction_contact_person_dialog = true;
        } else if (customer.property_count > 1) {
          _this.transaction_property_dialog = true;
        }

        if (customer.persons_count == 1) {
          var firstPerson = _this.lodash.head(customer.persons);

          _this.transaction_contact_person = firstPerson.id;
        }

        if (customer.property_count == 1) {
          var firstProperty = _this.lodash.head(customer.property);

          _this.transaction_property = firstProperty.id;
        }

        _this.checkJob();
      }
    }
  }
};