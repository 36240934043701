import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import DeleteSmallConfirm from "@/view/pages/partials/DeleteSmallConfirm";
import CreateContactPerson from "@/view/pages/customer/create-or-update/Create-Contact-Person";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    detail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    customer: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    person: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      dialog: false,
      contactPerson: {
        id: null,
        uuid: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        emails: [],
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0
      },
      contactPersons: []
    };
  },
  watch: {
    contactPersons: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var result = this.lodash.cloneDeep(param);
        this.$emit("saveContactPerson", result);
      }
    },
    detail: {
      deep: true,
      handler: function handler() {
        this.makeDefaultEntry();
      }
    }
  },
  methods: {
    appendDisplayName: function appendDisplayName() {
      var result = [];
      var _this$contactPerson = this.contactPerson,
          first_name = _this$contactPerson.first_name,
          last_name = _this$contactPerson.last_name;

      if (first_name) {
        result.push(first_name);
      }

      if (last_name) {
        result.push(last_name);
      }

      this.contactPerson.display_name = result.join(" ");
    },
    appendDialogDisplayName: function appendDialogDisplayName(index) {
      var result = [];
      var _this$contactPersons$ = this.contactPersons[index],
          first_name = _this$contactPersons$.first_name,
          last_name = _this$contactPersons$.last_name;

      if (first_name) {
        result.push(first_name);
      }

      if (last_name) {
        result.push(last_name);
      }

      this.contactPersons[index].display_name = result.join(" ");
    },
    makeDefaultEntry: function makeDefaultEntry() {
      var _this = this;

      try {
        var emailData = _this.lodash.filter(_this.detail.details, function (data) {
          return data.type === 1;
        });

        var contactData = _this.lodash.filter(_this.detail.details, function (data) {
          return data.type === 2;
        });

        if (_this.detail.first_name) {
          var firstEntry = {
            id: null,
            menu: false,
            uuid: uuidv4(),
            title: _this.lodash.toString(_this.detail.salutation),
            first_name: _this.lodash.toString(_this.detail.first_name),
            last_name: _this.lodash.toString(_this.detail.last_name),
            display_name: _this.lodash.toString(_this.createDisplayName()),
            primary_email: _this.lodash.head(emailData) ? _this.lodash.toString(_this.lodash.head(emailData).value) : null,
            primary_phone: _this.lodash.head(contactData) ? _this.lodash.toString(_this.lodash.head(contactData).value) : null,
            default: 1,
            will_notified: 1,
            position: null
          };
          firstEntry.emails = [firstEntry.primary_email];

          _this.contactPersons.splice(0, 1, firstEntry);
        }
      } catch (error) {
        _this.contactPersons.splice(0, 1);

        _this.logError(error);
      }
    },
    createDisplayName: function createDisplayName() {
      var firstname = "";

      if (this.detail.first_name) {
        firstname = this.detail.first_name.toLowerCase();
      }

      var lastname = "";

      if (this.detail.last_name) {
        lastname = this.detail.last_name.toLowerCase();
      }

      return firstname + lastname;
    },
    pushContactPerson: function pushContactPerson() {
      this.contactPersons.push({
        id: null,
        title: "mr",
        menu: false,
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        emails: [],
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0
      });
    },
    removeContactPerson: function removeContactPerson(index) {
      this.contactPersons.splice(index, 1);
    }
  },
  components: {
    PhoneTextField: PhoneTextField,
    DeleteSmallConfirm: DeleteSmallConfirm,
    CreateContactPerson: CreateContactPerson
  },
  mounted: function mounted() {
    if (this.isDialog) {
      if (this.contactPersons.length <= 0) {
        this.contactPersons.push({
          id: null,
          title: "mr",
          menu: false,
          first_name: null,
          last_name: null,
          display_name: null,
          primary_email: null,
          primary_phone: null,
          emails: [],
          will_notified: 1,
          position: null,
          default: 0
        });
      }
    }

    this.makeDefaultEntry();

    if (this.person > 0) {
      this.contactPersons = [this.getPersonById(this.customer, this.person)];
    }
  }
};